import React from "react"
import styled from "styled-components"
import media from "styled-media-query";

const Card = ({
    title,
    desc,
    date,
    category,
    image,
    tag
  }) => {
  return (
    <Wrapper style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url('${image.src}')`,
    }}>
      <Text>
        <div>
          <PostItemCategory>
            <span>
              {category}
            </span>
          </PostItemCategory>
          <Title>{title}</Title>
          <Desc>{desc}</Desc>
          <PostItemTag>
          { tag.map( (tags) =>
            <span>
              <TagColor color={tags.color} />
              {tags.name}
            </span>
          )}
          </PostItemTag>
        </div>
        <Date>{date}</Date>
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  border-radius: 8px;
  padding: 25px;
  color: var(--color-text);

   &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.3s ease;
   }
`

const PostItemCategory = styled.p`
  margin-bottom: 1rem;
  font-weight: 500;

  & span {
    font-size: 0.8rem;
    color: #000;
    border-radius: 4px;
    padding: 5px 10px;
    display: inline-block;
    background: var(--color-secondary);
  }
`

const PostItemTag = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  
  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.6rem;

    ${media.lessThan("small")`
      font-size: 0.8rem;
    `}
  }
`

const TagColor = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 0.2rem;
  background: ${props => props.color ? props.color : 'var(#2d2)'};
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: space-between;

  & > * {
    display: block;
  }
`

const Title = styled.h3`
  width: 100%;
  max-width: 350px;
  color: var(--color-text);
  margin: 0.5rem 0;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.3;

  ${media.lessThan("small")`
    font-size: 1.2rem;
  `}
`

const Desc = styled.p`
  line-height: 1.3;
  width: 100%;
  max-width: 350px;
  font-size: 0.8rem;
  color: var(--color-text);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0 0 1rem;

  ${media.lessThan("small")`
    font-size: 0.9rem;
  `}
`

const Date = styled.p`
  font-size: 0.7rem;
  color: var(--color-text-grey);
`

export default React.memo(Card)
