import React, { useState } from "react";
import styled from 'styled-components';
import media from "styled-media-query";

import api from '../services/api';

function Exclusive() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  async function handleSubmit(e) {
    e.preventDefault();

    if(email !== "" && message !== "") {
      const response = await api.post('/email/blog', {
        email,
        message
      })

      if(response.status === 200) {
        alert("Mensagem enviada com sucesso!!!");
      } else {
        alert("Ops, algo deu errado :(")
      }
    } else {
      alert("Favor digitar em todos os campos")
    }


    setEmail("");
    setMessage("");
  }

  return (
    <>
      <ExclusiveWrapper>
        <ExclusiveSection>
          <ExclusiveTexts>
            <ExclusiveTitle>Ficou com alguma dúvida?</ExclusiveTitle>
            <ExclusiveDescription>
              É só enviar sua dúvida para nossa equipe!!!
            </ExclusiveDescription>
          </ExclusiveTexts>
          <ExclusiveForm onSubmit={handleSubmit}>
            <input 
              type="email" 
              name="email" 
              placeholder="Digite seu melhor E-mail" 
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea 
              type="text" 
              name="text" 
              placeholder="Digite sua dúvida, nossa equipe está à disposição para te ajudar!!!" 
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <button className="exclusive-button" type="submit" value="Submit">
              Enviar
            </button>
          </ExclusiveForm>
        </ExclusiveSection>
      </ExclusiveWrapper>
    </>
  );
}

const ExclusiveWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 80px auto;
  padding: 40px;
  border-radius: 8px;
  border: 2px solid var(--color-secondary);
  box-shadow: 0 0 0.2rem var(--color-secondary);

  ${media.lessThan("small")`
    padding: 20px;
  `}
`

const ExclusiveSection = styled.section`
    text-align: left;
`

const ExclusiveTexts = styled.div`
  width: 70%;

  ${media.lessThan("small")`
    width: 100%;
  `}
`

const ExclusiveTitle = styled.h1`
    color: var(--color-secondary);
    font-size: 1.8rem;
    margin: 0;
`

const ExclusiveDescription = styled.p`
    font-size: 1rem;
    margin: 10px 0;
`

const ExclusiveForm = styled.form`
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
    display: grid;
    //grid-template-columns: 35% 35% 20%;
    align-items: center;
    text-align: left;
    gap: 20px;

    input {
      color: #FFF;
      height: 40px;
      padding: 0 15px;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid var(--color-secondary);
      background: transparent;

      ${media.lessThan("small")`
        padding: 10px;
      `}
    }

    textarea {
      color: #FFF;
      height: 78px;
      padding: 15px;
      font-size: 12px;
      border-radius: 4px;
      border: 1px solid var(--color-secondary);
      background: transparent;

      ${media.lessThan("small")`
        padding: 10px;
      `}
    }

    button {
        width: 40%;
        height: 40px;
        color: var(--color-background);
        background: var(--color-secondary);
        border-radius: 0.4rem;
        padding: 0.4rem 1.5rem;
        border: 0.1rem solid var(--color_text);
        font-size: 12px;
        font-weight: 500;
        cursor: pointer;
        transition: 0.2s ease-in;

        &:hover {
            background: transparent;
            color: var(--color-text);
        }

        ${media.lessThan("small")`
          font-size: 14px;
        `}
    }
`

export default Exclusive;

