import React from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import styled from "styled-components"
import media from "styled-media-query";

function SkeletonLoading() {
  return (
    <Container>
      <Grid>
        {Array(6)
          .fill()
          .map((item, index) => (
            <List key={index}>
              <div className="item-group">
                <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Title>
                        <Skeleton width={`70%`} height={25}/>
                        <Skeleton width={`40%`} height={25}/>
                    </Title>
                    <Desc>
                        <Skeleton count={1} width={`70%`} height={15}/>
                        <Skeleton count={1} width={`60%`} height={15}/>
                        <Skeleton count={1} width={`10%`} height={15}/>
                    </Desc>
                </SkeletonTheme>
              </div>
            </List>
        ))}
      </Grid>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  margin: 50px auto;
  max-width: 1100px;
  padding: 0 20px;
`

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  list-style: none;

  & > li {
    margin-bottom: 0;
  }

  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
  `}
`

const List = styled.li`
  list-style: none;
  cursor: pointer;
  height: 300px;
  border-radius: 8px;
  padding: 20px;
  background: var(--color-background-second);
  opacity: 1;
  transition:all 0.2s ease;

  &:nth-child(1) {
    grid-column: 1 / span 2;
  }
  &:nth-child(4) {
    grid-column: 2 / span 2;
  }
  &:nth-child(7) {
    grid-column: 1 / span 3;
  }

  ${media.lessThan("medium")`
    height: 250px;

    &:nth-child(1) {
      grid-column: 1;
    }
    &:nth-child(4) {
      grid-column: 1;
    }
    &:nth-child(7) {
      grid-column: 1;
    }
  `}
`

const Title = styled.h1`
  display: grid;
`

const Desc = styled.p`
  margin-top: 10px;
`



export default SkeletonLoading;