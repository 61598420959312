import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import media from "styled-media-query";

import Layout from "../components/Layout"
import Seo from "../components/Seo"

import { Instagram } from '@styled-icons/boxicons-logos/Instagram'
import { Github } from '@styled-icons/bootstrap/Github'
import { LinkedinSquare } from '@styled-icons/boxicons-logos/LinkedinSquare'
import { Html5 } from '@styled-icons/boxicons-logos/Html5'
import { Css3 } from '@styled-icons/boxicons-logos/Css3'
import { Javascript } from '@styled-icons/boxicons-logos/Javascript'
import { ReactLogo } from '@styled-icons/boxicons-logos/ReactLogo'
import { Smartphone } from '@styled-icons/material-outlined/Smartphone'
import { Figma } from '@styled-icons/boxicons-logos/Figma'
import { Nodejs } from '@styled-icons/boxicons-logos/Nodejs'
import { Mongodb } from '@styled-icons/simple-icons/Mongodb'
import { Git } from '@styled-icons/boxicons-logos/Git'

const About = ({ data }) => {
  const markdown = data.allMarkdownRemark.edges[0].node.html

  return (
    <Layout>
      <Seo title="Sobre o autor" />
      <AuthorImage>
        <StaticImage
          layout="constrained"
          formats={["auto", "webp", "avif"]}
          src="../images/me.png"
          quality={95}
          alt="Apresentação"
          style={{width: 150, borderRadius: '80%'}}
        />
      </AuthorImage>
      <Container
        dangerouslySetInnerHTML={{ __html: markdown ?? "" }}
      ></Container>
      <AuthorSocial>
        <h2>Saiba mais</h2>
        <AuthorSocialLinks>
            <LinkSocial 
                href="https://www.instagram.com/lipe.valdez/"
                target="_blank" 
                rel="noopener noreferrer"
            >
                <Instagram color='#e6e6e6'/>
            </LinkSocial>
            <LinkSocial 
                href="https://www.linkedin.com/in/lipe-dev"
                target="_blank" 
                rel="noopener noreferrer"
            >
                <LinkedinSquare color='#e6e6e6'/>
            </LinkSocial>
            <LinkSocial 
                href="https://github.com/f3l1p3v4"
                target="_blank" 
                rel="noopener noreferrer"
            >
                <Github color='#e6e6e6'/>
            </LinkSocial>
        </AuthorSocialLinks>
      </AuthorSocial>
      <AuthorSkill>
        <h2>Ferramentas</h2>
        <AuthorSkillLinks>
          <LinkSocialSkill>
              <Html5/>
              <p>HTML</p>
          </LinkSocialSkill>
          <LinkSocialSkill>
              <Css3/>
              <p>CSS</p>
          </LinkSocialSkill>
          <LinkSocialSkill>
              <Javascript/>
              <p>Javascript</p>
          </LinkSocialSkill>
          <LinkSocialSkill>
              <ReactLogo/>
              <p>React</p>
          </LinkSocialSkill>
          <LinkSocialSkill>
              <Smartphone/>
              <p>React Native</p>
          </LinkSocialSkill>
          <LinkSocialSkill>
              <Git/>
              <p>Git</p>
          </LinkSocialSkill>
          <LinkSocialSkill>
              <Figma/>
              <p>Figma</p>
          </LinkSocialSkill>
          <LinkSocialSkill>
              <Nodejs/>
              <p>Nodejs</p>
          </LinkSocialSkill>
          <LinkSocialSkill>
              <Mongodb/>
              <p>Mongodb</p>
          </LinkSocialSkill>
        </AuthorSkillLinks>
      </AuthorSkill>
    </Layout>
  )
}

const AuthorImage = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`

const Container = styled.main`
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
  max-width: 800px;

  h1 {
    font-weight: 700;
    color: var(--color-text);
    margin-bottom: 2rem;
    font-size: 2.5rem; 
  }

  p {
    line-height: 1.68;
    margin-bottom: var(--sizing-md);
  }

  a {
    text-decoration: none;
    //color: var(--color-blue) !important;
    * {
     // color: var(--color-blue) !important;
    }
    &:hover,
    &:active {
      text-decoration: underline;
    }
  }

  ${media.lessThan("large")`
    padding: 0 20px;
  `}
`

const AuthorSocial = styled.div`
    margin: 0 auto;
    text-align: center;
    width: 100%;
    max-width: 800px;
    display: grid;
    justify-content: center;
    align-items: center;

    & h2 {
      margin-top: 1rem;
      font-size: 2rem;
      line-height: 1.68;
      color: #fff;
      margin-bottom: 1rem;
    }
`;

const AuthorSocialLinks = styled.div`
    display: flex;
    width: 100%;
    max-width: 500px;
    justify-content: center;
    text-align: center;
    gap: 10px;
`;

const LinkSocial = styled.a`
    & svg {
        width: 40px;
        border: 1px solid var(--color-text);
        text-decoration: none;
        transition: all 0.1s ease-in-out;
        padding: 6px;
        margin: 0 0 0 10px;
        border-radius: 4px;
        
        ${media.lessThan("medium")`
            margin: 0 10px 0 0;
        `}
    }
    & svg:hover {
        border: 1px solid var(--background);
        box-shadow: 0 0 0.4rem var(--color-text);
    }
`;

const AuthorSkill = styled.div`
    margin: 30px auto;
    text-align: center;
    padding: 0 20px;
    width: 100%;
    max-width: 800px;
    display: grid;
    justify-content: center;
    align-items: center;

    & h2 {
      margin-top: 1rem;
      font-size: 2rem;
      line-height: 1.68;
      color: #fff;
      margin-bottom: 1rem;
    }
`;

const AuthorSkillLinks = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    max-width: 600px;
    justify-content: center;
    text-align: center;
    gap: 20px;

    ${media.lessThan("medium")`
      grid-template-columns: 1fr 1fr 1fr;
    `}
`;

const LinkSocialSkill = styled.div`
    transition: all 0.1s ease-in-out;
    padding: 6px;
    border-radius: 4px;
    display: flex;
    justify-content: left;
    align-items: center;
    color: var(--_color_0);
    cursor: pointer;

    & svg {
      width: 2.2rem;
    }

    & p {
      font-size: 0.6rem;
      margin: 0 4px;
    }

    &:hover {
        border: 1px solid var(--background);
        box-shadow: 0 0 0.4rem var(--color-text);
    }
`;


export default About

export const pageQuery = graphql`
query {
  allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/content/author/" } }) {
    edges {
      node {
        html
      }
    }
  }
}
`
