import * as React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => {

  return (
    <Layout>
      <Seo title="Página não encontrada" />
      <Container>
        <TitleWrap>
          <Title>404:</Title>
          <TitleSecond>Página não encontrada :(</TitleSecond>
          <Desc>Você acabou de pegar uma rota que não existe...</Desc>
          <ErrorLink to="/">Voltar para o início!</ErrorLink>
        </TitleWrap>
      </Container>
    </Layout>
  )
}

const Container = styled.main`
  width: 100%;
  max-width: 1150px;
  padding: 100px 20px 100px;
  margin: 0 auto;
`

const TitleWrap = styled.div`
  font-weight: 700;
`

const Title = styled.h1`
  font-weight: 700;
  margin: 0 0 10px;
  font-size: 5rem;
  background: -webkit-linear-gradient(#1cffb3, #5996f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const TitleSecond = styled.h2`
  margin: 0 0 10px;
  font-size: 2.5rem;
  background: -webkit-linear-gradient(#1cffb3, #5996f0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Desc = styled.h2`
  font-weight: 400;
  margin-bottom: 40px; 
  font-size: 1.4rem;
`

const ErrorLink = styled(Link)`
    text-decoration: none;
    background: -webkit-linear-gradient(#1cffb3, #5996f0);
    color: var(--color-background);
    border-radius: 0.4rem;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: 0.2s ease-in;
    &:hover {
        background: transparent;
        color: var(--color-text);
    }
`

export default NotFoundPage

