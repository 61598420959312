import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query";

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PostGrid from "../components/PostGrid"

const Category = ({
  pageContext,
  data,
}) => {
  const [posts, setPosts] = useState([])
  const currentCategory = pageContext.category
  const postData = data.allMarkdownRemark.edges

  useLayoutEffect(() => {
    const filteredPostData = currentCategory
      ? postData.filter(
          ({ node }) => node?.frontmatter?.category === currentCategory
        )
      : postData

    filteredPostData.forEach(({ node }) => {
      const { id } = node
      const { slug } = node.fields
      const { title, desc, date, category, image, tag } = node.frontmatter

      setPosts(prevPost => [
        ...prevPost,
        {
          id,
          slug,
          title,
          desc,
          image,
          date,
          category,
          tag
        },
      ])
    })
  }, [currentCategory, postData])

  const categoryTitle = currentCategory

  return (
    <Layout>
      <Seo title={categoryTitle} />
      <Main>
        <Content>
          <CategoryTitle>{categoryTitle}</CategoryTitle>
          <CategoryDesc>Todos os posts relacionados a categoria {categoryTitle}</CategoryDesc>
          <PostGrid posts={posts} />
        </Content>
      </Main>
    </Layout>
  )
}

const Main = styled.main`
  min-width: var(--min-width);
  min-height: calc(100vh - var(--nav-height) - var(--footer-height));
  background-color: var(--color-background);
`

const Content = styled.div`
  box-sizing: content-box;
  width: 87.5%;
  max-width: 1000px;
  padding-top: 20px;
  padding-bottom: var(--sizing-lg);
  margin: 0 auto;
  text-align: center;
`

const CategoryDesc = styled.p`
  margin-bottom: 10px;  
  
  ${media.lessThan("small")`
    width: 90%;
  `} 
`

const CategoryTitle = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: var(--sizing-md);
  color: var(--color-text);
  line-height: 1.21875;

  ${media.lessThan("small")`
    padding: 0 20px;
    margin-top: 10px;
  `}
`

export const query = graphql`
  query {
    allMarkdownRemark (
      filter: { fileAbsolutePath: { regex: "/content/blog/" } }
      sort: { fields: [frontmatter___date], order: ASC }
      limit: 1000
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
      }
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            category
            date(formatString: "DD-MM-YYYY")
            desc
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            tag {
              name
              color
            }
          }
          fields {
            slug
          }
        }
      }
    }
}
`

export default Category
