import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PostGrid from "../components/PostGrid"
import Pagination from "../components/Pagination";
import Skeleton from "../components/SkeletonLoading";

const BlogIndex = ({ data, pageContext }) => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([])
  const postData = data.allMarkdownRemark.edges

  const { currentpage, numpages } = pageContext
  const isFirst = currentpage === 1
  const isLast = currentpage === numpages
  const prevPage = currentpage - 1 === 1 ? '/' : `/page/${currentpage - 1}`
  const nextPage = `/page/${currentpage + 1}`
  
  useEffect(() => {
    const timer = setTimeout(() => {
    postData.forEach(({ node }) => {
      const { id } = node
      const { slug } = node.fields
      const { title, desc, date, category, image, tag } = node.frontmatter

      
      setPosts(prevPost => [
        ...prevPost,
        {
          id,
          slug,
          title,
          desc,
          date,
          category,
          image,
          tag
        },
      ])
    })

    setLoading(false);
  }, 3000);

  return () => clearTimeout(timer);
  }, [postData])
  
  console.log(posts)
  return (
    <Layout>
      <Seo title="Todos posts" />
      {loading && <Skeleton />}
        
      {!loading &&
        <PostList>
          <PostGrid posts={posts} />      
        </PostList>
      }
      <Pagination 
        isFirst={isFirst} 
        isLast={isLast}
        currentPage={currentpage}
        numPages={numpages}
        prevPage={prevPage}
        nextPage={nextPage}
      />
    </Layout>
  )
}

const PostList = styled.ul`
  width: 100%;
  margin: 50px auto;
  max-width: 1100px;
  padding: 0 20px;
  list-style: none;
`

export default BlogIndex

export const pageQuery = graphql`
query PostList($skip: Int!, $limit: Int!) {
  allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/content/blog/" } }
    sort: { fields: [frontmatter___date], order: DESC }
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          category
          date(formatString: "DD-MM-YYYY")
          desc
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tag {
            name
            color
          }
        }
        fields {
          slug
        }
      }
    }
  }
}
`
