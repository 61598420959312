import React from "react";
import { StaticImage } from "gatsby-plugin-image"

import styled from 'styled-components';
import media from "styled-media-query";

const CardCourseLateral = () => {
    return (
        <CardCourseLateralWrapper>
            <div className="flex-col">
                <CardCourseLateralImage>
                    <StaticImage
                      layout="constrained"
                      formats={["auto", "webp", "avif"]}
                      src="../images/course-dank.png"
                      quality={95}
                      alt="Apresentação"
                      style={{width: "100%"}}
                    />
                </CardCourseLateralImage>
                <div className="flex justify-between">
                    <h2>Pacote Full-Stack</h2>
                    <p>Com as habilidades que você irá GANHAR, é possível FATURAR de <strong style={{color: 'rgb(7, 210, 7)'}}>R$8.000</strong> ATÉ <strong style={{color: 'rgb(7, 210, 7)'}}>R$10.000</strong> POR MÊS através das tecnologias, aulas e projetos práticos que serão ensinados!
                    </p>
                </div>
                <CourseGroupDetails>
                  <div>
                    <CourseGroupSubTitle>Qtd de aulas</CourseGroupSubTitle>
                    <CourseGroupSubDescription>Mais de 2500 aulas</CourseGroupSubDescription>
                  </div>
                  <div>
                    <CourseGroupSubTitle>Autor</CourseGroupSubTitle>
                    <CourseGroupSubDescription>Danki Code</CourseGroupSubDescription>
                  </div>
                </CourseGroupDetails>

                <CardCourseLateralButton
                  href="https://go.hotmart.com/Q79065872S"
                  className="btn"
                >
                  Saiba Mais
                </CardCourseLateralButton>
            </div>
        </CardCourseLateralWrapper>                                                                                                                                                                            
    )
}

const CardCourseLateralWrapper = styled.article`
    //width: 100%;
    //height: 100%;
    border-radius: 8px;
    padding: 15px;
    border: 1px solid #444;
    & .flex-col {
      flex-direction: column;
    }
    & .flex {
      display: grid;
    }
    & .justify-between {
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      & h2 {
        font-size: 0.8rem;
        font-weight: 600;
        margin: 0;
        letter-spacing: 0px;
        color: var(--color-text);

          ${media.lessThan("large")`
            font-size: 1.4rem;
            margin: 10px 0;
          `}

          ${media.lessThan("small")`
            font-size: 1.1rem;
          `}
      }
      & p {
        font-size: 0.6rem;
        font-weight: 400;
        margin: 5px 0;
        line-height: 0.7rem;

        ${media.lessThan("large")`
          font-size: 1rem;
          line-height: 1.4rem;
          margin: 10px 0;
        `}

        ${media.lessThan("small")`
          font-size: 0.8rem;
          margin: 10px 0;
        `}
      }
    }
    & .mt-16 {
        margin-top: 10px;
    }              
    & select {
        width: 100%;
        height: 46px;
        border: 2px solid #444;
        border-radius: 4px;
        color: #fff;
        background: var(--color-background-second);
        padding: 10px;
        font-size: 0.8rem;
        ${media.lessThan("medium")`
          height: 56px;
        `}
    }

  ${media.lessThan("large")`
    padding: 30px;
  `}
`

export const CardCourseLateralImage = styled.div`
    width: 100%;
    padding: 0;
    margin-bottom: 10px;

    & img {
      width: 100%;
      border-radius: 8px;
    }
`

export const CardCourseLateralButton = styled.a`
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 4px;
    color: var(--color-background-second);
    background: rgb(7, 210, 7);
    font-weight: 600;
    margin: 10px 0 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    text-decoration: none;
    font-size: 0.7rem;
    transition: filter 0.3s;
    &:hover {
      background: rgb(7, 180, 7);
    }

    ${media.lessThan("large")`
      font-size: 1.2rem;
      height: 56px;
      //margin: 10px 0;
    `}

    ${media.lessThan("medium")`
      height: 56px;
    `}
    
    ${media.lessThan("small")`
      height: 46px;
      font-size: 0.7rem;
    `}
`

export const CourseGroupDetails = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CourseGroupSubTitle = styled.h3`
  font-size: 0.6rem;
  line-height: 1rem;
  font-weight: 500;
  color: var(--color-text);
  margin: 0;

  ${media.lessThan("large")`
    font-size: 1rem;
    line-height: 1.4rem;
    margin: 10px 0;
  `}

  ${media.lessThan("small")`
    font-size: 0.9rem;
    margin: 5px 0;
  `}
`

export const CourseGroupSubDescription = styled.p`
  font-size: 0.6rem;
  line-height: 0rem;
  font-weight: 400;
  color: var(--color-text-light);
  margin: 5px 0;
  display: flex;
  align-items: center;

  ${media.lessThan("large")`
    font-size: 1rem;
    line-height: 1.4rem;
    margin: 10px 0;
  `}

  ${media.lessThan("small")`
    font-size: 0.8rem;
  `}
`

export default CardCourseLateral;