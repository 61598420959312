import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const Pagination = ({ isFirst, isLast, currentPage, numPages, prevPage, nextPage }) => (
    <PaginationWrapper>
        {!isFirst &&
            <PaginationLink to={prevPage} style={{ color: 'var(--text)' }}>👈 página anterior</PaginationLink>
        }

        <p>{currentPage} de {numPages}</p>

        {!isLast &&
            <PaginationLink to={nextPage} style={{ color: 'var(--text)' }}>proxima página 👉</PaginationLink>
        }
    </PaginationWrapper>
)

export const PaginationWrapper = styled.section`
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    font-size: .8rem;
    align-items: center;
    color: var(--color-text);
    display: flex;
    padding: 1rem 1rem 2rem;
    justify-content: space-between;

    & p {
        color: var(--color-text);
        margin-bottom: 0;
    }
`
export const PaginationLink = styled(Link)`
    color: var(--color-text);
    text-decoration: none;
    transition: color 0.5s;

    &:hover {
        color: var(--color-primary)
    }
`

export default Pagination;