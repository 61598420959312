import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query";

import Card from "./card"

const PostGrid = ({ posts }) => {
  return (
      <Grid role="list">
        {posts.map(data => {
          return (
            <List role="listitem">
              <Link to={`${data.slug}`}>
                <Card
                  title={data.title}
                  desc={data.desc}
                  date={data.date}
                  category={data.category}
                  image={data.image.childImageSharp.fluid}
                  tag={data.tag}
                />
              </Link>
            </List>
          )
        })}
      </Grid>
  )
}

const Grid = styled.ul`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  & > li {
    margin-bottom: 0;
  }

  ${media.lessThan("medium")`
    grid-template-columns: 1fr;
  `}
`

const List = styled.li`
  list-style: none;
  cursor: pointer;
  height: 300px;
  border-radius: 8px;
  opacity: 1;
  transition:all 0.2s ease;

  &:nth-child(1) {
    grid-column: 1 / span 2;
  }
  &:nth-child(4) {
    grid-column: 2 / span 2;
  }
  &:nth-child(7) {
    grid-column: 1 / span 3;
  }

  ${media.lessThan("medium")`
    height: 250px;

    &:nth-child(1) {
      grid-column: 1;
    }
    &:nth-child(4) {
      grid-column: 1;
    }
    &:nth-child(7) {
      grid-column: 1;
    }
  `}

  a {
    display: block;
    height: 100%; 
    padding: 2px 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    position: relative;
    border-radius: 8px;
    text-align: left;
    
    &::before,
    &::after {
      inset: 0px;
      content: "";
      position: absolute;
      transition: transform 0.2s ease 0s;
      border-radius: 8px;
    }
    &::before,
    &::after {
      inset: 0px;
      content: "";
      position: absolute;
      transition: transform 0.2s ease 0s;
    }
    &::before {
      border-top: 2px solid var(--color-secondary);
      border-bottom: 2px solid var(--color-secondary);
      transform: scale3d(0, 1, 1);
    }
    &::after {
      border-left: 2px solid var(--color-secondary);
      border-right: 2px solid var(--color-secondary);
      transform: scale3d(1, 0, 1);
    }
    &:hover::before,
    &:hover::after {
      transform: scale3d(1, 1, 1);
      transition: transform 0.5s;
    }
  }

  & .gatsby-image-wrapper {
    transition: opacity 1s ease-out, transform 0.5s ease;
  }

  a:hover,
  a:focus {
    .gatsby-image-wrapper {
      transform: scale(1.03);
    }
  }
`

export default PostGrid
