import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import media from "styled-media-query";

const NavigationInternal = ({ previous, next }) => {
  return (
    <NavigationPost>
      {previous && (
        <NavigationLink to={previous.fields.slug} rel="prev" style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url('${previous.frontmatter.image.childImageSharp.fluid.src}')`,
            }}>
              <NavigationTitle>
              ← {previous.frontmatter.title}
              </NavigationTitle>
        </NavigationLink>
      )}
      {next && (
        <NavigationLink to={next.fields.slug} rel="next" style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url('${next.frontmatter.image.childImageSharp.fluid.src}')`,
          }}>
            <NavigationTitle>
            {next.frontmatter.title} →
            </NavigationTitle>
        </NavigationLink>
      )}
    </NavigationPost>
  )
}

const NavigationPost = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  cursor: pointer;
`

const NavigationLink = styled(Link)`
  width: 49%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  border-radius: 8px;
  padding: 0 20px;
  text-decoration: none;
  transition: 0.2s ease-in;

  &:hover {
    box-shadow: 0 0 1em #000000;
  }

  ${media.lessThan("small")`
    height: 80px;
    padding: 0 10px;
  `}
`

const NavigationTitle = styled.p`
  font-size: 1rem;
  color: var(--color-text);
  margin: 0;

  ${media.lessThan("small")`
    font-size: 0.8rem;
  `}
`

export default NavigationInternal
