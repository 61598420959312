import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import media from "styled-media-query";

import kebabCase from "lodash/kebabCase"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Comments from "../components/Comments"
import NavigationInternal from "../components/NavigationInternal"
import CardCourseLateral from "../components/CardCourseLateral"

const BlogPostTemplate = ({ data }) => {
  const post = data.markdownRemark
  const { previous, next } = data

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <Header style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)), url('${post.frontmatter.image.childImageSharp.fluid.src}')`,
            }}>
              <PostHeader>
                <PostItemCategory>
                  <span>
                      <Category to={`/category/${kebabCase(post.frontmatter.category)}/`}>{post.frontmatter.category}</Category>
                  </span>
                </PostItemCategory>
                <Title>{post.frontmatter.title}</Title>
                <Desc>{post.frontmatter.desc}</Desc>
                <Info>
                  <PresentationImage>
                    <StaticImage
                      layout="constrained"
                      formats={["auto", "webp", "avif"]}
                      src="../images/me.png"
                      quality={95}
                      alt="Apresentação"
                      style={{width: 40, borderRadius: '80%'}}
                    />
                  </PresentationImage>
                  <PostAuthor to="/author">
                    Felipe Valdez
                    <Time>{post.frontmatter.date}</Time>
                  </PostAuthor>
                </Info>
                <PostItemTag>
                  {post.frontmatter.tag.map((tags) =>
                    <Link to={`/tag/${kebabCase(tags.name)}/`}>
                      <TagColor color={tags.color} />
                      {tags.name}
                    </Link>
                  )}
                </PostItemTag>
              </PostHeader>
            </Header>
      <MainPost>
        <PostWrapper
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
          <Comments />
          <NavigationInternal previous={previous} next={next}/>
        </PostWrapper>
        <CourseAboutCardLateral>
          <CardCourseLateral/>
        </CourseAboutCardLateral>
      </MainPost>
    </Layout>
  )
}

const MainPost = styled.article`
    width: 100%;
    max-width: 1050px;
    padding: 30px 20px 0;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 75% 22%;
    align-items: flex-start;
    gap: 25px;
    position: relative;

    ${media.lessThan("large")`
      grid-template-columns: 1fr;
      display: flex;
      flex-direction: column-reverse;
      max-width: 800px;
      padding: 10px 20px 0;
    `}
`

const CourseAboutCardLateral = styled.div`
    border-radius: 8px;
    height: auto;
    position: sticky;
    top: 80px;
    margin-top: 3rem;
    bottom: 1rem;
    z-index: 999;
    box-shadow: 0 0 0.4rem #141415;
    background: var(--color-background-second);

    ${media.lessThan("large")`
      position: relative;
      top: 40px;
      margin-top: 0.5rem;
      z-index: 0;
    `}
`

const PostWrapper = styled.article`
  width: 100%;
  position: relative;
  
  & section {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
`

const Header = styled.div`
    width: 100%;
    padding: 100px 20px;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    ${media.lessThan("small")`
      padding: 40px 20px;
    `}
`

const PostHeader = styled.div`
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const PostItemCategory = styled.p`
  margin-bottom: 1rem;
  font-weight: 500;

  & span {
    font-size: 0.8rem;
    color: #000;
    border-radius: 4px;
    padding: 5px 10px;
    display: inline-block;
    background: var(--color-secondary);
  }
`

const Title = styled.h1`
  width: 100%;
  max-width: 500px;
  color: var(--color-text);
  margin-top: 1rem;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.2;

  ${media.lessThan("small")`
    font-size: 1.6rem;
  `}
`

const Desc = styled.p`
  width: 100%;
  max-width: 550px;
  line-height: 1.3;
  margin-top: 0px;
  font-size: 1rem;
  color: var(--color-text);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0.6rem  0 2rem;

  ${media.lessThan("small")`
    font-size: 1rem;
  `}
`

const Info = styled.div`
  width: var(--post-width);
  color: var(--color-text);
  margin: 0 auto;
  display: flex;
  justify-content: space-beetwen;
  align-items: center;
  margin-bottom: var(--sizing-md);
`

const PresentationImage = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 10px;
`

const PostAuthor = styled(Link)`
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-text);

  ${media.lessThan("small")`
    font-size: 1rem;
  `}
`

const Time = styled.p`
  //margin-top: 12px;
  font-size: 0.7rem;
  font-weight: 400;
  margin: 0;
  color: var(--color-text-grey);
`

const PostItemTag = styled.p`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  gap: 10px;
  
  & a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    text-decoration: none;
    color: var(--color-text);
  }
`

const TagColor = styled.span`
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 0.4rem;
  background: ${props => props.color ? props.color : 'var(#2d2)'};
`

const Category = styled(Link)`
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: var(--color-text-1);
  text-decoration: none;
`

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "DD-MM-YYYY")
        desc
        category
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        tag {
          name
          color
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
